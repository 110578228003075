

























































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LeaderboardSection',
  components: {
  },
  data() {
    return {
      headers: [
        {
          text: 'Team',
          align: 'start',
          value: 'name',
        },
        { text: 'Score', value: 'score' },
      ],
    };
  },
  props: {
    teams: {
      type: Array,
      default: () => [
      ],
    },
  },
});
